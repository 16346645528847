import React, { Component, useContext } from 'react';

import PropTypes from 'prop-types';

import tastyworksApi from 'utils/tastyworksApi';

const propTypes = {
  children: PropTypes.node.isRequired,
};

const defaultContextValue = {
  data: {
    // set your initial data shape here
    authenticated: false,
  },
  set: () => {},
};

const { Provider, Consumer } = React.createContext(defaultContextValue);

class ContextProviderComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...defaultContextValue,
      set: this.setData,
    };
  }

  componentDidMount() {
    tastyworksApi.validateSession().then((authenticated) => {
      this.setData({ authenticated });
    });
  }

  setData = (newData) => {
    this.setState((state) => ({
      data: {
        ...state.data,
        ...newData,
      },
    }));
  };

  render() {
    return <Provider value={this.state}>{this.props.children}</Provider>;
  }
}

ContextProviderComponent.propTypes = propTypes;

export const useDefaultContext = () => useContext(Consumer);

export { Consumer as default, ContextProviderComponent };
