export const DESKTOP_VERSION = process.env.DESKTOP_VERSION || '0.41.0';
export const TASTYWORKS_API = process.env.TASTYWORKS_API || 'https://api.tastyworks.com/';
export const LOGIN_SUCCESS_URL = process.env.LOGIN_SUCCESS_URL || 'https://manage.tastytrade.com/index.html';
export const STOCK_TICKER_API_URL = process.env.STOCK_TICKER_API_URL;
export const IP_COUNTRY_API_URL = 'https://api.country.is/';
export const LOGIN_COOKIE_DOMAIN = '.tastytrade.com';
export const LOGIN_COOKIE_KEY = 'ember_simple_auth-session';
export const LOGIN_AUTHENTICATOR = 'authenticator:twucs';
export const FRESHCHAT_HOST = 'https://wchat.freshchat.com';
export const FRESHCHAT_TOKEN = 'ddb8f362-46f4-4536-a634-e2911424f6ed';
export const GH_META_FEATURED_POSITION_ID = 4366317004;
export const RECAPTCHA_SITE_KEY = process.env.GATSBY_RECAPTCHA_SITE_KEY || '6Lef-KsdAAAAANaVpVmgDjpRcllhF5oPw2MMKvYD';
export const GOOGLE_MAPS_API_KEY = process.env.GATSBY_GOOGLE_MAPS_API_KEY || 'AIzaSyA27rrYpHARwTKDb_vdIIqJQZYtS51dvIk';
export const GREENHOUSE_PROXY_URL =
  process.env.GATSBY_GREENHOUSE_PROXY_URL ||
  'https://q6k2y5yenl.execute-api.us-east-1.amazonaws.com/development/greenhouse-proxy-function';
export const CONTACT_US_FORM_URL =
  process.env.GATSBY_CONTACT_US_FORM_URL ||
  'https://q6k2y5yenl.execute-api.us-east-1.amazonaws.com/development/contact-email';
export const ONETRUST_DOMAIN_ID = process.env.GATSBY_ONETRUST_DOMAIN_ID || 'b01342ba-67b5-4080-933e-d17d3e85fdee';
export const HOMEPAGE_TITLE = 'Homepage Title';
export const PAGE_TITLE = 'Page Title';
export const NAV_BREAKPOINT = 991;
export const NEW_USER = 'new';
export const RETURN_USER = 'return';
export const AUTH_USER = 'authorized';

export const CONTENT_ALIGN = {
  top: 'flex-start',
  center: 'center',
  bottom: 'flex-end',
};

export const MOBILE_CONTENT_ALIGN = {
  left: 'left',
  right: 'right',
  center: 'center',
};

export const COLUMN_SPAN_OPTS = {
  fullWidth: 'full width',
  halfWidth: 'half width',
  twoThirds: '2/3 width',
  oneThird: '1/3 width',
  oneFourth: '1/4 width',
  threeFourth: '3/4 width',
  oneSixth: '1/6 width',
  fiveTwelths: '5/12 width',
  sevenTwelths: '7/12 width',
  oneTwelth: '1/12 width',
};

export const columnSpanMap = {
  [COLUMN_SPAN_OPTS.fullWidth]: 12,
  [COLUMN_SPAN_OPTS.halfWidth]: 6,
  [COLUMN_SPAN_OPTS.twoThirds]: 8,
  [COLUMN_SPAN_OPTS.oneThird]: 4,
  [COLUMN_SPAN_OPTS.oneFourth]: 3,
  [COLUMN_SPAN_OPTS.threeFourth]: 9,
  [COLUMN_SPAN_OPTS.oneSixth]: 2,
  [COLUMN_SPAN_OPTS.fiveTwelths]: 5,
  [COLUMN_SPAN_OPTS.sevenTwelths]: 7,
  [COLUMN_SPAN_OPTS.oneTwelth]: 1,
};

export const disclaimerTypes = {
  DISCLAIMER: 'disclaimer',
  FOOTNOTE: 'footnote',
};

export const DEVICE_VISIBILITY = {
  mobile_only: 'mobile-only',
  desktop_only: 'desktop-only',
};
