import React, { useContext, useEffect, useReducer, useState } from 'react';

import { useFetchAlertBanner } from 'hooks/queries/useFetchAlertBanner';

function getDefaultState() {
  const DEFAULT_STATE = {
    showAlert: true,
    headerElementHeight: 128,
  };

  let storedState = {};

  return {
    ...DEFAULT_STATE,
    ...storedState,
  };
}

const ACTION_SET_SHOW_ALERT = 'setShowAlert';
const ACTION_SET_HEADER_HEIGHT = 'setHeaderElementHeight';

function layoutReducer(state, action) {
  switch (action.type) {
    case ACTION_SET_SHOW_ALERT:
      return {
        ...state,
        showAlert: action.payload ?? state.showAlert,
      };
    case ACTION_SET_HEADER_HEIGHT:
      return {
        ...state,
        headerElementHeight: action.payload,
      };
    default:
      return state;
  }
}

const LayoutContext = React.createContext({
  state: getDefaultState(),
  dispatch: () => {},
  setShowAlert: (showAlert) => {},
  setHeaderElementHeight: (height) => {},
});

export const LayoutProvider = ({ children }) => {
  const [state, dispatch] = useReducer(layoutReducer, getDefaultState());

  const { siteAlerts } = useFetchAlertBanner();

  useEffect(() => {
    dispatch({
      type: ACTION_SET_SHOW_ALERT,
      payload: {
        showAlert: siteAlerts.isActive,
      },
    });
  }, [siteAlerts]);

  const [contextValue, setContextValue] = useState({
    state,
    dispatch,
    setShowAlert: (showAlert) => dispatch({ type: ACTION_SET_SHOW_ALERT, payload: showAlert }),
    setHeaderElementHeight: (height) => dispatch({ type: ACTION_SET_HEADER_HEIGHT, payload: height }),
  });

  // Update context value and trigger re-render
  // This patterns avoids unnecessary deep renders
  // https://reactjs.org/docs/context.html#caveats
  useEffect(() => {
    setContextValue((contextValue) => ({
      ...contextValue,
      state,
    }));
  }, [state]);

  return <LayoutContext.Provider value={contextValue}>{children}</LayoutContext.Provider>;
};

export const useLayoutContext = () => useContext(LayoutContext);
